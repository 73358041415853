module services {
    export module manifest {
        export class manifestCrewService implements interfaces.manifest.IManifestCrewService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            get(macId: number, manId: number): ng.resource.IResourceClass<interfaces.manifest.IManifestCrew> {
                return this.$resource<interfaces.manifest.IManifestCrew>(this.ENV.DSP_URL + "ManifestCrew/Get", {
                    macId: macId,
                    manId: manId
                }, {
                        get: {
                            method: "GET",
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            remove(macId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ManifestCrew/Delete", {
                    macId: macId
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "ManifestCrew/Save");
            }
        }
    }
    angular.module("app").service("manifestCrewService", services.manifest.manifestCrewService);
}